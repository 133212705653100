/* eslint-disable */

var emprise_params = window.emprise_params;
import Swiper, { Autoplay, Navigation, Pagination, FreeMode } from "swiper";

var empriseDestiText = "";

if (emprise_params.location_status === "show") {
  getLocation();
}

import { computeStyles } from "@popperjs/core";
// Import instances
import Pristine from "./pristine";
import tippy from "./tippy";
import FsLightbox from "../styles/components/lightbox/lightbox";

/* Product Single Page -> Social share popover */
var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover-share"]'));
popoverTriggerList.map(function (popoverTriggerEl) {
  const popoverId = popoverTriggerEl.getAttribute("data-content-id");
  const popoverButtonId = popoverTriggerEl.getAttribute("id");
  if (popoverId) {
    const contentEl = document.getElementById(popoverId).innerHTML;

    tippy("#" + popoverButtonId, {
      theme: "rlr-custom-tippy",
      trigger: "click",
      allowHTML: true,
      interactive: true,
      content: contentEl,
      maxWidth: 375,
      onShow: function (instance) {
        var buttons = instance.popper.getElementsByClassName("rlr-share__list");
        Array.from(buttons).forEach((b) => {
          b.addEventListener("click", function () {
            let socialClasses = this.getAttribute("class").toString();
            let socialClass = socialClasses.replace("rlr-share__list ", "");
            // Social share
            switch (socialClass) {
              case "rlr-js--facebook":
                fbShare({
                  url: empriseShareObj.post_url,
                  redirectUri: empriseShareObj.post_url,
                  hashtag: "#" + empriseShareObj.hashtag,
                  fbAppId: empriseShareObj.facebook_app_id,
                  quote: empriseShareObj.post_title
                });
                break;
              case "rlr-js--twitter":
                tw({
                  url: empriseShareObj.post_url,
                  title: empriseShareObj.post_title,
                  hashtags: [empriseShareObj.hashtag]
                });
                break;

              case "rlr-js--reddit":
                reddit({
                  url: empriseShareObj.post_url,
                  title: empriseShareObj.post_title
                });
                break;

              case "rlr-js--whatsapp":
                whatsapp({
                  url: empriseShareObj.post_url,
                  title: empriseShareObj.post_title
                });
                break;

              case "rlr-js--messenger":
                messenger({
                  url: empriseShareObj.post_url,
                  fbAppId: empriseShareObj.facebook_app_id
                });
                break;

              default:
                email({
                  url: empriseShareObj.post_url,
                  title: empriseShareObj.post_title,
                  description: empriseShareObj.post_desc,
                  subject: empriseShareObj.post_title
                });
            }
          });
        });

        /* Copy Link */
        var rlr_js_copy_link = instance.popper.getElementsByClassName("rlr-copylink__icon");
        rlr_js_copy_link[0].addEventListener("click", function (e) {
          /* Select the text field */

          var copyText = e.target.previousElementSibling; //e.target.parentElement.parentElement.children[0];
          /* Select the text field */
          copyText.select();
          copyText.setSelectionRange(0, 99999); /* For mobile devices */

          /* Copy the text inside the text field */

          // text area method
          let textArea = copyText;
          textArea.focus();
          textArea.select();
          return new Promise((res, rej) => {
            // here the magic happens
            document.execCommand("copy") ? res() : rej();
          });
        });
      }
    });
  }
});

/* Product Theme Change load Product Theme related items */
window.productThemeChange = function (event) {
  var theme_id = event.value;
  event.id = "theme_id_" + theme_id;
  var theme_selected_id = "theme_id_" + theme_id;
  var data = "action=emprise_select_child_themes&product_theme_id=" + theme_id + "&nonce=" + emprise_params.nonce;
  var selectedThemeText = event.options[event.selectedIndex].text;

  var thisElement = event.parentElement.nextElementSibling;
  thisElement.innerHTML =
    '<label class="form-label form-label--light" id="product_theme_sub_options_title">' + '</label><ul class="rlr-checkboxes" id="product_theme_sub_options"></ul>';

  thisElement.lastChild.innerHTML = emprise_params.loading_text;

  ajaxCall(data, function (returnVal) {
    thisElement.lastChild.innerHTML = "";
    if (returnVal !== null) {
      var response = JSON.parse(returnVal);

      if (response.content !== null && response.content !== "") {
        thisElement.innerHTML =
          '<label class="form-label form-label--light" id="product_theme_sub_options_title">' +
          selectedThemeText +
          '</label><ul class="rlr-checkboxes" id="product_theme_sub_options"></ul>';
        thisElement.lastChild.innerHTML = response.content;
      }
    }
  });

  /* var selectedEventDelBtn =
    event.parentElement.parentElement.parentElement.parentElement.querySelectorAll(
      '.js-delete-repeater'
    ); */
  var selectedEventDelBtn = event.parentElement.parentElement.querySelectorAll(".rlr-js-delete-repeater");
  for (var i = 0; i < selectedEventDelBtn.length; i++) {
    selectedEventDelBtn[i].setAttribute("id", "theme_del_id_" + theme_id);
  }
};

/* Function to call ajax (internal function) */
var ajaxCall = (data, callback) => {
  var returnVal = "";
  var newAjaxCall = new XMLHttpRequest();
  newAjaxCall.open("POST", emprise_params.admin_ajax_url, true);
  newAjaxCall.onreadystatechange = function () {
    if (newAjaxCall.readyState != 4 || newAjaxCall.status != 200) return null;
    returnVal = newAjaxCall.responseText;
    callback(returnVal);
  };
  newAjaxCall.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  newAjaxCall.send(data);
};

/* Function to call ajax for image (internal function) */
var ajaxCallForImage = (imagesList, callback) => {
  var returnVal = "";
  var newAjaxCall = new XMLHttpRequest();
  newAjaxCall.open("POST", emprise_params.admin_ajax_url, true);
  newAjaxCall.onreadystatechange = function () {
    if (newAjaxCall.readyState != 4 || newAjaxCall.status != 200) return null;
    returnVal = newAjaxCall.responseText;
    callback(returnVal);
  };
  newAjaxCall.setRequestHeader("Accept", "multipart/form-data");
  newAjaxCall.send(imagesList);
};
window.onload = empriseCheckCookie();

// Yes/No for Age based Price
var prodAgeItems = document.querySelectorAll(".ageToggle:checked");
for (let index = 0; index < prodAgeItems.length; index++) {
  const element = prodAgeItems[index];
  ageGroupShowHide(element);
}
var prodAgeGroupOptCheck = document.getElementsByClassName("ageToggle");
if (prodAgeGroupOptCheck !== null && prodAgeGroupOptCheck.length > 0) {
  Array.from(prodAgeGroupOptCheck).forEach(function (element) {
    element.addEventListener("click", ageGroupShowHide, false);
  });
}
// Set price based on age range to "NO" if price activity is "Private"
var prodGroupTypeCheck = document.querySelectorAll('input[name="rlr_price_activity"]');
Array.from(prodGroupTypeCheck).forEach(function (element) {
  element.addEventListener("click", ageGroupShowHide, false);
});

// Tour Occurance types yearly, weekly, specific dates
var prodOccurance = document.querySelectorAll('input[name="rlr_tour_occurance"]:checked');
for (let index = 0; index < prodOccurance.length; index++) {
  const element = prodOccurance[index];
  tourOccurance(element);
}
var prodOccuranceEvent = document.querySelectorAll('input[name="rlr_tour_occurance"]');
Array.from(prodOccuranceEvent).forEach(function (element) {
  element.addEventListener("click", tourOccurance, false);
});

// Date based percent show/hide
var dateBasedPercent = document.querySelectorAll('input[name="rlr_product_form_daterange"]:checked');
for (let index = 0; index < dateBasedPercent.length; index++) {
  const element = dateBasedPercent[index];
  datePercentShowHide(element);
}
var dateBasedPercentClick = document.querySelectorAll('input[name="rlr_product_form_daterange"]');
Array.from(dateBasedPercentClick).forEach(function (element) {
  element.addEventListener("click", datePercentShowHide, false);
});

// Product Type Change load Product Category
var productTypeOptions = document.getElementsByClassName("product_type");
if (productTypeOptions !== null && productTypeOptions.length > 0) {
  Array.from(productTypeOptions).forEach(function (element) {
    element.addEventListener("change", productTypeChange, false);
  });
}
function productTypeChange(event) {
  var product_type_id = event.target.value;

  document.getElementById("emprise_main_cat").innerHTML = "<option value=''>" + emprise_params.loading_text + "</option>";
  document.getElementById("emprise_sub_cat").innerHTML = "<option value=''></option>";
  var data = "action=emprise_tour_main_cat_select&top_id=" + product_type_id + "&nonce=" + emprise_params.nonce;

  ajaxCall(data, function (returnVal) {
    if (returnVal !== null) {
      var response = JSON.parse(returnVal);
      document.getElementById("emprise_main_cat").innerHTML = response.content;
      document.getElementById("emprise_main_cat").disabled = false;
    }
  });
}

// Product Category on Change -> load Product Sub Category
var product_cat_options = document.getElementById("emprise_main_cat");
if (product_cat_options !== null) {
  product_cat_options.addEventListener("change", productCatChange, false);
}
function productCatChange(event) {
  var cat_id = event.target.value;

  document.getElementById("emprise_sub_cat").innerHTML = "<option value=''>" + emprise_params.loading_text + "</option>";
  document.getElementById("emprise_sub_cat").disabled = true;
  var data = "action=emprise_select_child_cats&cat_id=" + cat_id + "&nonce=" + emprise_params.nonce;

  ajaxCall(data, function (returnVal) {
    if (returnVal !== null) {
      var response = JSON.parse(returnVal);
      document.getElementById("emprise_sub_cat").innerHTML = response.content;
      document.getElementById("emprise_sub_cat").disabled = false;
    }
  });
}

// On Inclusion Exclusion Change
window.inexChange = function (event, type) {
  var selectedOptId = event.value;
  var inputId = type + "_" + selectedOptId;
  var selectedOptText = event.options[event.selectedIndex].text;
  var thisElement = event.parentElement;
  var nextElements = thisElement.nextElementSibling;

  if (nextElements !== null) {
    nextElements.remove();
  }

  thisElement.insertAdjacentHTML(
    "afterend",
    '<span><label class="form-label">' +
      selectedOptText +
      '</label><input type="text" maxlength="70" class="form-control js-form-title" placeholder="Short description of inclusion item" id="' +
      inputId +
      '" /></span>'
  );
};

// On Next click save form
window.savingOnFormOnNext = function (rlrCount) {
  var inputValType = "normal";
  var imagesList = "";
  if (rlrCount === 1) {
    /* Product Title and Type and all */
    var product_title = document.getElementById("rlr-product-form-product-title").value;
    var product_type = document.getElementsByName("product_type");
    var product_type_value = "";
    if (product_type.length > 0) {
      for (var i = 0; i < product_type.length; i++) {
        if (product_type[i].checked) {
          product_type_value = product_type[i].value;
        }
      }
    }
    var data = "action=emprise_set_listing_tmp_data&prod_title=" + product_title + "&prod_type=" + product_type_value + "&nonce=" + emprise_params.nonce;
  } else if (rlrCount === 2) {
    /* Product Category, Sub-Category and Description */
    var product_main_cat = document.getElementById("emprise_main_cat").value;
    var product_sub_cat = document.getElementById("emprise_sub_cat").value;
    var prod_desc = document.getElementById("emprise_prod_desc").value;
    var data =
      "action=emprise_set_listing_tmp_data&prod_cat=" + product_main_cat + "&prod_subcat=" + product_sub_cat + "&prod_desc=" + prod_desc + "&nonce=" + emprise_params.nonce;
  } else if (rlrCount === 3) {
    /* Product Theme and Tags */
    var productThemeOpt = document.getElementsByClassName("product_theme_opt");
    const prodThemes = {};
    const productTagIds = [];
    var prodThemeVals = "";
    var productTagValue = "";
    if (productThemeOpt !== null && productThemeOpt.length > 0) {
      Array.from(productThemeOpt).forEach(function (element) {
        var childThemeIds = [];
        var childThemeCheckedOpt = document.querySelectorAll(".product_theme_main_id_" + element.value + ":checked");

        var mainThemeId = element.value;
        for (var i = 0; i < childThemeCheckedOpt.length; i++) {
          childThemeIds.push(childThemeCheckedOpt[i].value);
        }
        prodThemes[mainThemeId] = childThemeIds;
      });
      prodThemeVals = JSON.stringify(prodThemes);
    }

    var productTagCheckedOpt = document.querySelectorAll(".product_tags:checked");
    if (productTagCheckedOpt.length > 0) {
      for (var i = 0; i < productTagCheckedOpt.length; i++) {
        productTagIds.push(productTagCheckedOpt[i].value);
      }
    }
    if (productTagIds.length > 0) {
      productTagValue = JSON.stringify(productTagIds);
    }

    var data = "action=emprise_set_listing_tmp_data&prod_themes=" + prodThemeVals + "&prod_tags=" + productTagValue + "&nonce=" + emprise_params.nonce;
  } else if (rlrCount === 4) {
    /* Product Images */
    inputValType = "image";
    var images = document.getElementById("image-preview");
    imagesList = retrieveImage(images, "jsForm", "emprise_set_listing_tmp_data", emprise_params.nonce);
  } else if (rlrCount === 5) {
    /* Product Pickup, starting point and starting point detail */
    const startingPointOptions = [];
    var meetingPointValue = "";
    var startingPointCheckedOpt = document.querySelectorAll(".meeting_points_options:checked");
    for (var i = 0; i < startingPointCheckedOpt.length; i++) {
      startingPointOptions.push(startingPointCheckedOpt[i].value);
    }
    if (startingPointOptions.length > 0) {
      meetingPointValue = JSON.stringify(startingPointOptions);
    }
    var starting_point_input = document.getElementById("emprise_starting_point_input").value;
    var starting_point_lat = document.getElementById("starting_point_lat").value;
    var starting_point_lon = document.getElementById("starting_point_lon").value;
    var starting_point_detail = document.getElementById("emprise_starting_point_detail").value;

    var data = "action=emprise_set_listing_tmp_data&starting_point_input=" + starting_point_input + "&starting_point_lat=" + starting_point_lat;
    data = data + "&starting_point_lon=" + starting_point_lon + "&starting_point_detail=" + starting_point_detail;
    data = data + "&startingPointOptions=" + meetingPointValue + "&nonce=" + emprise_params.nonce;
  } else if (rlrCount === 6) {
    /* Product Age and Health Restrictions */
    var ageGroupOptions = [];
    var hardshipLevelVal = "";
    var ageGroupCheckedOpt = document.querySelectorAll(".age_groups_types:checked");
    for (var i = 0; i < ageGroupCheckedOpt.length; i++) {
      ageGroupOptions.push(ageGroupCheckedOpt[i].value);
    }
    empriseSetCookie("age_group_options", ageGroupOptions, 365);
    ageGroupOptionShowHide();

    var hardshipLevel = document.querySelector('input[name="hardship_level"]');
    if (hardshipLevel !== null) {
      var hardshipLevelChecked = document.querySelector('input[name="hardship_level"]:checked');
      if (hardshipLevelChecked !== null) {
        hardshipLevelVal = hardshipLevelChecked.value;
      } else {
        var radios = document.getElementsByName("hardship_level");

        for (var i = 0, length = radios.length; i < length; i++) {
          if (radios[i].checked) {
            // do whatever you want with the checked radio
            hardshipLevelVal = radios[i].value;

            // only one radio can be logically checked, don't check the rest
            break;
          }
        }
      }
    }

    var data = "action=emprise_set_listing_tmp_data&hardship_level=" + hardshipLevelVal + "&ageGroupOptions=" + JSON.stringify(ageGroupOptions) + "&nonce=" + emprise_params.nonce;
  } else if (rlrCount === 7) {
    /* Product Duration */
    var tourDurationVal = "";
    var durationVal = "";
    var durationUnitVal = "";
    var tourDuration = document.querySelector('input[name="tour_duration"]:checked');
    if (tourDuration !== null) {
      tourDurationVal = tourDuration.value;
    }
    var durationInDays = document.getElementById("emprise_duration_in_days");
    if (durationInDays !== null) {
      durationVal = durationInDays.value;
    }
    var durationUnit = document.getElementById("duration_unit");
    if (durationUnit !== null) {
      durationUnitVal = durationUnit.value;
    }

    var data =
      "action=emprise_set_listing_tmp_data&tour_duration=" +
      tourDurationVal +
      "&duration_in_days=" +
      durationVal +
      "&duration_unit=" +
      durationUnitVal +
      "&nonce=" +
      emprise_params.nonce;
  } else if (rlrCount === 8) {
    /* Product Itinerary */
    var itineraryFieldOpt = document.getElementsByClassName("emprise_itinerary_field");

    const duration_itinerary = {};
    if (itineraryFieldOpt !== null && itineraryFieldOpt.length > 0) {
      Array.from(itineraryFieldOpt).forEach(function (element, c) {
        var to_save_fields = ["title", "detail", "accomodation", "iframe_link", "meals", "inclusions", "exclusions"];
        var itinerary_fields = element.children;
        var itinerary_fields_values = {};
        for (var i = 0; i < to_save_fields.length; i++) {
          var fields = to_save_fields[i];
          var val = itinerary_fields[i].querySelector(".form-control").value;
          if (val !== "") {
            var test = val.replace(/["]/g, "###");
            itinerary_fields_values[fields] = test;
          }
        }
        duration_itinerary[c] = itinerary_fields_values;
        if (duration_itinerary[c]["iframe_link"] !== "") {
          duration_itinerary[c]["iframe_link"] = encodeURIComponent(duration_itinerary[c]["iframe_link"]);
        }
      });
    }
    var data = "action=emprise_set_listing_tmp_data&duration_itinerary=" + JSON.stringify(duration_itinerary);
  } else if (rlrCount === 9) {
    /* Product Dates & Prices  */
    var discountArr = [];
    var dateRangeDiscount = "no";
    var groupType = document.querySelector('input[name="rlr_price_activity"]:checked').value;
    var maxCapacity = document.getElementById("rlr_max_capacity").value;
    var tourOccurance = document.querySelector('input[name="rlr_tour_occurance"]:checked').value;

    var dateAvailablePickrId = "rlr_js_product_form_date_multiple_weekly";
    if (tourOccurance == "weekly") {
      dateAvailablePickrId = "rlr_js_product_form_date_multiple_no_weekends";
    } else if (tourOccurance == "yearly") {
      dateAvailablePickrId = "rlr_js_product_form_date_multiple";
    }

    var multiDate = document.getElementById(dateAvailablePickrId).value;

    var ageGroupOpt = document.querySelector('input[name="age_groups"]:checked').value;
    var ageArr = ageGroupOptionShowHide("", "yes");
    var regularPrice = 0;
    var otherPrice = {};
    if (ageArr) {
      for (var key in ageArr) {
        if (ageArr.hasOwnProperty(key)) {
          if (key === "adult") {
            regularPrice = ageArr[key];
          } else {
            otherPrice[key] = ageArr[key];
          }
        }
      }
    }
    if (regularPrice === "") {
      regularPrice = 0;
    }
    if (document.querySelector('input[name="rlr_product_form_daterange"]:checked') !== null) {
      dateRangeDiscount = document.querySelector('input[name="rlr_product_form_daterange"]:checked').value;
    }

    if (dateRangeDiscount.toLowerCase() === "yes") {
      var discountDateField = document.querySelectorAll(".discount_date_range.flatpickr-input");
      for (let index = 0; index < discountDateField.length; index++) {
        var discountRange = {};
        const selectedDiscountDateRange = discountDateField[index].value;
        var discountDateRange = selectedDiscountDateRange.replace(" to ", "||");
        discountRange["date_range"] = discountDateRange;
        var discountPercentField = document.querySelectorAll('input[data-name="discount-percent"]');
        const discountPercent = discountPercentField[index].value;
        discountRange["percentage"] = discountPercent;
        discountArr.push(discountRange);
      }
    }
    var addonsFieldOpt = document.getElementsByClassName("addons_field");
    const addons = {};
    if (addonsFieldOpt !== null && addonsFieldOpt.length > 0) {
      Array.from(addonsFieldOpt).forEach(function (element, c) {
        var to_save_fields = ["addons_title", "addons_price"];
        var addons_fields = element.children;
        var addons_fields_values = {};
        for (var i = 0; i < to_save_fields.length; i++) {
          var fields = to_save_fields[i];
          var val = addons_fields[i].querySelector(".form-control").value;
          if (val !== "") {
            var test = val.replace(/["]/g, "###");
            addons_fields_values[fields] = test;
            // this.Vals.replace(/["]/g, '###');
          }
        }
        addons[c] = addons_fields_values;
      });
    }
    var data =
      "action=emprise_set_listing_tmp_data&group_type=" +
      groupType +
      "&max_capacity=" +
      maxCapacity +
      "&tour_occurance=" +
      tourOccurance +
      "&multi_date=" +
      multiDate +
      "&age_group=" +
      ageGroupOpt +
      "&regular_price=" +
      regularPrice +
      "&other_prices=" +
      JSON.stringify(otherPrice) +
      "&date_range=" +
      dateRangeDiscount +
      "&date_discount=" +
      JSON.stringify(discountArr) +
      "&addons=" +
      JSON.stringify(addons) +
      "&nonce=" +
      emprise_params.nonce;
  } else if (rlrCount === 10) {
    /* Product Product Inclusion and Exclusion */
    const inexWithDesc = {};
    const inclusionArr = {};
    const exclusionArr = {};
    var inexDesc = document.getElementById("emprise_inexDesc").value;
    var inclusionOpts = document.getElementsByClassName("inclusion_opts");
    var exclusionOpts = document.getElementsByClassName("exclusion_opts");

    if (inclusionOpts !== null && inclusionOpts.length > 0) {
      for (let index = 0; index < inclusionOpts.length; index++) {
        const inclusionOpt = inclusionOpts[index].value;
        if (inclusionOpt !== "") {
          var inclusionTxt = "";
          if (document.getElementById("inclusion_" + inclusionOpt) !== null) {
            inclusionTxt = document.getElementById("inclusion_" + inclusionOpt).value;
          }
          inclusionArr[inclusionOpt] = inclusionTxt;
        }
      }
    }
    if (exclusionOpts !== null && exclusionOpts.length > 0) {
      for (let index = 0; index < exclusionOpts.length; index++) {
        const exclusionOpt = exclusionOpts[index].value;
        if (exclusionOpt !== "") {
          var exclusionTxt = "";
          if (document.getElementById("exclusion_" + exclusionOpt) !== null) {
            exclusionTxt = document.getElementById("exclusion_" + exclusionOpt).value;
          }
          exclusionArr[exclusionOpt] = exclusionTxt;
        }
      }
    }
    if (inexDesc !== "" || inexDesc !== null || Object.keys(inclusionArr).length > 0 || Object.keys(exclusionArr).length > 0) {
      inexWithDesc["description"] = inexDesc;
      if (Object.keys(inclusionArr).length > 0) {
        inexWithDesc["inclusions"] = inclusionArr;
      }
      if (Object.keys(exclusionArr).length > 0) {
        inexWithDesc["exclusions"] = exclusionArr;
      }
      var data = "action=emprise_set_listing_tmp_data&inclusions_exclusions=" + JSON.stringify(inexWithDesc) + "&nonce=" + emprise_params.nonce;
    }
  } else if (rlrCount === 11) {
    /* Product FAQ */
    const faqs = {};
    var questionFields = document.querySelectorAll(".questions");
    var answerFields = document.querySelectorAll(".answers");

    if (questionFields.length > 0) {
      for (var j = 0; j < questionFields.length; j++) {
        var faq_fields_values = {};
        var question = questionFields[j].value;
        var answer = answerFields[j].value;
        if (question !== "") {
          question = question.replace(/["]/g, "###");
          faq_fields_values["questions"] = question;
        }
        if (answer !== "") {
          answer = answer.replace(/["]/g, "###");
          faq_fields_values["answers"] = answer;
        }
        faqs[j] = faq_fields_values;
      }
    }
    var nxtBtns = document.getElementsByClassName("jsNext");
    if (nxtBtns !== null) {
      for (var i = 0; i < nxtBtns.length; i++) {
        nxtBtns[i].children[0].innerHTML = emprise_params.publish_btn;
      }
    }
    var data = "action=emprise_set_listing_tmp_data&faqs=" + JSON.stringify(faqs) + "&nonce=" + emprise_params.nonce;
  } else if (rlrCount === 12) {
    var data = "action=emprise_save_listing" + "&nonce=" + emprise_params.nonce;
    var showDiv = document.getElementById("emprise-final-step");
    var newDiv = document.createElement("div");
    var newSpan = document.createElement("span");
    newDiv.className = "spinner-border text-primary";
    newSpan.className = "sr-only";
    newSpan.innerHTML = " " + emprise_params.saving_form_msg;
    showDiv.innerHTML = "";
    showDiv.appendChild(newDiv);
    showDiv.appendChild(newSpan);
    var nxtBtns = document.getElementsByClassName("jsPrev");
    if (nxtBtns !== null) {
      for (var i = 0; i < nxtBtns.length; i++) {
        nxtBtns[i].classList.add("disabled");
      }
    }
  }

  // Ajax call to store Values on Next button click
  if (inputValType === "normal") {
    ajaxCall(data, function (returnVal) {
      if (returnVal !== null) {
        var response = JSON.parse(returnVal);
        if (typeof response.status !== "undefined" && response.status === "done") {
          empriseDeleteCookie("guest_auth_token");
          empriseDeleteCookie("age_group_options");

          document.getElementById("jsForm").reset();
          var showDiv = document.getElementById("emprise-final-step");
          showDiv.classList.remove("alert-primary");
          showDiv.classList.add("alert");
          showDiv.classList.add("alert-success");
          var newNode = document.createElement("div");
          newNode.innerHTML = " " + emprise_params.saved_form_msg;
          showDiv.innerHTML = "";
          showDiv.appendChild(newNode);
        }
      }
    });
  } else if (inputValType === "image") {
    ajaxCallForImage(imagesList, function (returnVal) {});
  }
};

// On Previous Button Click
var prevBtn = document.getElementsByClassName("jsPrev");
if (prevBtn.length > 0 && prevBtn !== null) {
  Array.from(prevBtn).forEach(function (element) {
    element.addEventListener("click", prevBtnClick, false);
  });
}
function prevBtnClick(event) {
  var nxtBtns = document.getElementsByClassName("jsNext");
  if (nxtBtns !== null && nxtBtns.length > 0) {
    for (var i = 0; i < nxtBtns.length; i++) {
      nxtBtns[i].children[0].innerHTML = emprise_params.next_btn;
    }
  }
}

// Text in textarea to array
function textareaTextToArray(text) {
  var numberOfLineBreaks = (text.match(/\n/g) || []).length;
  var textToArray = [];
  Array.from(text.split(/\n/g) || []).forEach(function (lines, i) {
    textToArray.push(lines);
  });
  return textToArray;
}

var splideArrowEle = document.querySelector(".splide__arrows");
if (typeof splideArrowEle !== "undefined" && splideArrowEle !== null) {
  splideSliderShowHide(splideArrowEle);
}
function splideSliderShowHide(splideArrowElement) {
  var imageElement = "";
  if (document.getElementById("countimages") !== null && typeof document.getElementById("countimages") !== "undefined") {
    imageElement = document.getElementById("countimages");
  } else if (document.getElementById("countreviewimages") !== null && typeof document.getElementById("countreviewimages") !== "undefined") {
    imageElement = document.getElementById("countreviewimages");
  }

  if (imageElement !== null && typeof imageElement !== "undefined") {
    var totalImages = imageElement.value;
    if (totalImages === "") {
      totalImages = 0;
    }
    if (totalImages <= 3) {
      splideArrowElement.classList.add("rlr-product-form--hide");
    } else if (totalImages > 3) {
      splideArrowElement.classList.remove("rlr-product-form--hide");
    }
  }
}
function imageCounter(images, todoAction) {
  var imageElement = "";
  var required = true;
  if (document.getElementById("countimages") !== null && typeof document.getElementById("countimages") !== "undefined") {
    imageElement = document.getElementById("countimages");
  } else if (document.getElementById("countreviewimages") !== null && typeof document.getElementById("countreviewimages") !== "undefined") {
    imageElement = document.getElementById("countreviewimages");
    required = false;
  }
  if (imageElement !== null && typeof imageElement !== "undefined") {
    var totalImages = imageElement.value;
    if (totalImages === "") {
      totalImages = 0;
    }
    if (todoAction.toLowerCase() === "delete") {
      var val = parseInt(totalImages) - 1;
    } else {
      var val = parseInt(totalImages) + parseInt(images);
    }
    if (val === 0) {
      imageElement.value = "";
    } else {
      if (required) {
        if (document.querySelector(".emprise_image .rlr-error")) {
          document.querySelector(".emprise_image").classList.remove("has-danger");
          document.querySelector(".emprise_image").classList.add("has-success");
          document.querySelector(".emprise_image .rlr-error").style.display = "none";
        }
      }
      imageElement.value = val;
    }
    splideSliderShowHide(document.querySelector(".splide__arrows"));
  }
}
window.deleteImageBtn = function (event) {
  imageCounter(event, "delete");
};
window.addImageBtn = function (totalFiles) {
  imageCounter(totalFiles, "add");
};

// Function to retrieve Image Url and caption as array
function retrieveImage(imagesUl, elementId, actionName, nonce) {
  var imgArr = [];
  var filetonotremove = [];
  var oldImageInfo = {};
  var captionList = {};
  var imgLists = imagesUl.getElementsByTagName("img");
  var imgSrc = "";
  var formEle = document.querySelector("#" + elementId);
  var currentdate = new Date();
  var datetime = currentdate.getDate() + (currentdate.getMonth() + 1) + currentdate.getFullYear() + currentdate.getHours() + currentdate.getMinutes() + currentdate.getSeconds();

  var formData = new FormData(formEle);
  formData.append("action", actionName);
  for (var j = 0; j < imgLists.length; j++) {
    var mime = "";
    var captionSpan = imagesUl.getElementsByTagName("li")[j].getElementsByTagName("span")[0];
    imgSrc = imgLists[j].src;
    var filename = empriseRandomString(10);
    var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    var imageType = imgSrc.indexOf("base64") > -1;
    if (imageType) {
      mime = mimeType(imgSrc);
      filename = datetime + "_" + filename + "." + mime;
      var file = dataURLtoFile(imgSrc, filename);
      formData.append("file[" + j + "]", file);
    } else {
      filetonotremove.push(imgSrc);
      oldImageInfo[j] = imgSrc;
    }
    if (captionSpan.innerText !== "") {
      captionList[j] = captionSpan.innerText;
    }
  }
  formData.append("filetonotremove", JSON.stringify(filetonotremove));
  formData.append("oldImageInfo", JSON.stringify(oldImageInfo));
  formData.append("imagecaption", JSON.stringify(captionList));
  formData.append("nonce", nonce);
  return formData;
}

function dataURLtoFile(dataurl, filename) {
  var returnVal = {};
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

function mimeType(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1];
  var found = mime.match("image/jpeg");
  if (found !== null) {
    return "jpeg";
  }
  found = mime.match("image/jpg");
  if (found !== null) {
    return "jpg";
  }
  found = mime.match("image/gif");
  if (found !== null) {
    return "gif";
  }
  found = mime.match("image/png");
  if (found !== null) {
    return "png";
  }
  found = mime.match("image/bmp");
  if (found !== null) {
    return "bmp";
  }
  found = mime.match("image/tiff");
  if (found !== null) {
    return "tiff";
  }
  found = mime.match("image/svg+xml");
  if (found !== null) {
    return "svg+xml";
  }
}

function getExtFromURL(dataurl) {
  return dataurl.split(/[#?]/)[0].split(".").pop().trim();
}

// Load More
var loadMoreBtnItems = document.getElementsByClassName("emprise_load_more");
if (loadMoreBtnItems !== null && loadMoreBtnItems.length > 0) {
  Array.from(loadMoreBtnItems).forEach(function (element) {
    element.addEventListener("click", loadMoreCallback, false);
  });
}
function loadMoreCallback(event) {
  event.target.innerHTML = emprise_params.loading_text;
  var productId = event.target.parentElement.querySelector("input[name='emprise_product_id']").value;
  var itemPerPage = event.target.parentElement.querySelector("input[name='emprise_item_per_page']").value;
  var dataType = event.target.parentElement.querySelector("input[name='emprise_data_type']").value;
  var pageNumber = event.target.parentElement.querySelector("input[name='emprise_page_number']").value;
  var prevSibling = event.target.parentElement.previousElementSibling;
  var data =
    "action=emprise_load_more&item_per_page=" +
    itemPerPage +
    "&page_number=" +
    pageNumber +
    "&data_type=" +
    dataType +
    "&product_id=" +
    productId +
    "&nonce=" +
    emprise_params.nonce;
  ajaxCall(data, function (returnVal) {
    if (returnVal !== null) {
      var response = JSON.parse(returnVal);
      event.target.innerHTML = response.remaining;
      event.target.parentElement.querySelector("input[name='emprise_page_number']").value = parseInt(pageNumber) + 1;
      if (dataType === "product_review") {
        Array.from(response.content).forEach(function (ele) {
          var article = document.createElement("article");
          article.classList.add("rlr-review-card");
          article.setAttribute("itemscope", "");
          article.innerHTML = ele;
          event.target.parentElement.before(article);
        });
      } else {
        prevSibling.insertAdjacentHTML("beforeend", response.content);

        refreshFsLightbox();
      }
    }
  });
}

// Age Group Show/Hide
function ageGroupShowHide(event) {
  ageGroupOptionShowHide(event);
}
function ageGroupOptionShowHide(event = "", returnVal = "") {
  var checkedOpt = "no";
  var priceActivity = "";
  var ageGrpValues = {};
  var ageArr = [];
  if (document.querySelector('input[name="age_groups"]:checked') !== null) {
    checkedOpt = document.querySelector('input[name="age_groups"]:checked').value;
  }
  var priceActivity = document.querySelector('input[name="rlr_price_activity"]');

  if (priceActivity !== null) {
    var priceActivityOpt = null;
    /* IMPORTANT NOTE */
    /* if using querySelectorAll then to retrieve the :checked.value always do variable[0].value, if using only querySelector just do variable.value;; */
    if (document.querySelector('input[name="rlr_price_activity"]:checked') !== null) {
      priceActivityOpt = document.querySelector('input[name="rlr_price_activity"]:checked').value;
    } else {
      var inputElements = document.getElementsByClassName("rlr_price_activity_options");
      if (inputElements !== null && inputElements.length > 0) {
        for (var i = 0; inputElements[i]; ++i) {
          if (inputElements[i].checked) {
            priceActivityOpt = inputElements[i].value;
            break;
          }
        }
      }
    }
    if (priceActivityOpt === "Private") {
      document.querySelector('input[name="age_groups"]').checked = true;
      checkedOpt = "no";
    }
  }
  var ageGroupOptions = empriseGetCookie("age_group_options");
  if (ageGroupOptions) {
    ageArr = ageGroupOptions.split(",");
  }
  var allAgeGroupFields = document.querySelectorAll(".emprise-age-fields");
  for (let index = 0; index < allAgeGroupFields.length; index++) {
    allAgeGroupFields[index].parentElement.classList.add("rlr-product-form--hide");
    var allAgeGroupInputFields = allAgeGroupFields[index].children[0];
    allAgeGroupInputFields.removeAttribute("required");
  }

  if (checkedOpt === "no") {
    ageArr = ["adult"];
  }

  for (let selAgeGrp = 0; selAgeGrp < ageArr.length; selAgeGrp++) {
    const currentAgeGrp = ageArr[selAgeGrp];
    var ageGroupFields = document.querySelector("." + currentAgeGrp);
    ageGroupFields.parentElement.classList.remove("rlr-product-form--hide");
    var ageGroupInputField = ageGroupFields.children[0];
    ageGroupInputField.setAttribute("required", "");
    ageGrpValues[currentAgeGrp] = ageGroupInputField.value;
  }
  if (returnVal === "yes") {
    return ageGrpValues;
  }
}

// Tour Occurance require/remove
function tourOccurance(event) {
  var prodOccuranceVal = "yearly";
  const prodOccuranceDatesInput = document.getElementById("rlr_js_product_form_date_multiple");
  var prodOccuranceType = document.querySelector('input[name="rlr_tour_occurance"]:checked');
  if (prodOccuranceType !== null) {
    prodOccuranceVal = prodOccuranceType.value;
  }
  if (prodOccuranceVal === "fixed") {
    prodOccuranceDatesInput.setAttribute("required", "");
  } else {
    prodOccuranceDatesInput.removeAttribute("required", "");
  }
}

// Discount Date and percent field show/hide
function datePercentShowHide(event) {
  var selectedDateOpt = document.querySelector('input[name="rlr_product_form_daterange"]:checked').value;
  var dateBasedPercentRepeater = document.querySelector(".rlr-date-percent-repeater");
  var dateBasedPercentOpt = document.querySelectorAll(".rlr-dates-opt");
  Array.from(dateBasedPercentOpt).forEach(function (element) {
    var firstChild = element.children[0];
    var secondChild = element.children[1];
    //discount_date_range
    Array.from(firstChild.children).forEach((firstChildGrandchild) => {
      Array.from(firstChildGrandchild.getElementsByClassName("discount_date_range")).forEach((ele) => {
        if (selectedDateOpt === "no") {
          ele.removeAttribute("required");
        } else {
          ele.setAttribute("required", "");
        }
      });
    });
    Array.from(secondChild.children).forEach((secondChildgrandchild) => {
      var tagType = secondChildgrandchild.tagName;
      if (tagType.toLowerCase() === "input") {
        secondChildgrandchild.removeAttribute("required");
        if (selectedDateOpt === "no") {
          secondChildgrandchild.removeAttribute("required");
        } else {
          secondChildgrandchild.setAttribute("required", "");
        }
      }
    });
    if (selectedDateOpt === "no") {
      element.classList.add("rlr-js-input-groups__hide");
      dateBasedPercentRepeater.classList.add("rlr-js-input-groups__hide");
    } else {
      element.classList.remove("rlr-js-input-groups__hide");
      dateBasedPercentRepeater.classList.remove("rlr-js-input-groups__hide");
    }
  });
}

// Product Theme Add Another
var prod_add_another = document.getElementById("empriseAddAnotherTheme");
if (prod_add_another !== null) {
  prod_add_another.addEventListener("click", productAddAnotherTheme, false);
}
function productAddAnotherTheme() {
  var prodThemeItems = document.querySelectorAll(".product_theme_opt");

  var prodThemeOptions = prodThemeItems[prodThemeItems.length - 1];

  const fullId = prodThemeOptions.id;
  const fullCount = prodThemeOptions.length;
  const myArr = fullId.split("_");
  var id = myArr[2];

  var countRemoved = 0;

  for (var i = 0; i < fullCount; i++) {
    if (typeof prodThemeOptions.options[i] !== "undefined" && prodThemeOptions.options[i].value == id) {
      prodThemeOptions.remove(i);
      countRemoved++;
    }
  }

  if (parseInt(fullCount) - parseInt(countRemoved) === 2) {
    // document.getElementById('empriseAddAnotherTheme').style.display = 'none';
  }
}

// Product Theme Delete
var productThemeDel = document.getElementsByClassName("emprise_product_theme_delete");
if (productThemeDel !== null && productThemeDel.length > 0) {
  Array.from(productThemeDel).forEach(function (element) {
    element.addEventListener("click", prodThemeDelClick, false);
  });
}
function prodThemeDelClick(event) {
  var prodThemeItems = document.querySelectorAll(".product_theme_opt");

  if (parseInt(document.getElementById("countDelete").value) === 0) {
    document.getElementById("countDelete").value = prodThemeItems.length - 1;
  } else {
    document.getElementById("countDelete").value = parseInt(document.getElementById("countDelete").value) - 1;
  }

  const myArr = event.target.id.split("_");
  var delId = myArr[3];

  if (typeof delId !== "undefined") {
    var data = "action=emprise_theme_name&product_theme_id=" + delId + "&nonce=" + emprise_params.nonce;

    ajaxCall(data, function (returnVal) {
      if (returnVal !== null) {
        var response = JSON.parse(returnVal);
        var prodThemeOptions = prodThemeItems[prodThemeItems.length - 1];

        const fullId = prodThemeOptions.id;
        const fullCount = prodThemeOptions.length;
        const myIds = fullId.split("_");
        var id = myIds[2];

        var countRemoved = 0;

        for (var i = 0; i < prodThemeItems.length; i++) {
          var checkArr = "yes";
          var opt = document.createElement("option");
          opt.value = delId;
          opt.innerHTML = response.title;
          if (typeof prodThemeItems[i] !== "undefined") {
            for (var j = 0; j < prodThemeItems[i].options.length; j++) {
              if (prodThemeItems[i].options[j].value === delId) {
                checkArr = "no";
              }
            }
          }
          if (checkArr === "yes") {
            prodThemeItems[i].appendChild(opt);
          }
        }
      }
    });
  }

  let parentDom = event.target.parentNode;
  if (parentDom) {
    parentDom.remove();
    if (parseInt(document.getElementById("countDelete").value) === 1 && prodThemeItems.length <= 1) {
      const deleteButtons = document.querySelectorAll(".rlr-fieldrow  .rlr-js-delete-repeater" /* rlr-js-delete-repeater */);
      for (var k = 0; k < deleteButtons.length; k++) {
        deleteButtons[k].parentNode.removeChild(deleteButtons[k]);
      }
    }
  }
}
window.deleteProductThemeCallback = function (event) {
  prodThemeDelClick(event);
};

// Product Inclusion/Exclusion Delete
var inexDel = document.getElementsByClassName("inExDelete");
if (inexDel !== null && inexDel.length > 0) {
  Array.from(inexDel).forEach(function (element) {
    element.addEventListener("click", deleteParentNode, false);
  });
}
// Product Itinerary Delete
var itineraryDel = document.getElementsByClassName("itineraryDelete");
if (itineraryDel !== null && itineraryDel.length > 0) {
  Array.from(itineraryDel).forEach(function (element) {
    element.addEventListener("click", deleteParentNode, false);
  });
}
// Product Dates and Prices Delete
var datesPricesDel = document.getElementsByClassName("datesPricesDelete");
if (datesPricesDel !== null && datesPricesDel.length > 0) {
  Array.from(datesPricesDel).forEach(function (element) {
    element.addEventListener("click", deleteParentNode, false);
  });
}
// Function to delete parent node
function deleteParentNode(event) {
  let parentDom = event.target.parentNode;
  if (parentDom) {
    parentDom.remove();
  }
}

// Product Form -> Product Durations
var product_tour_duration_options = document.getElementsByName("tour_duration");
Array.from(product_tour_duration_options).forEach(function (element) {
  element.addEventListener("click", empriseChangeTourDuration, false);
});
function empriseChangeTourDuration(event) {
  var duration_counts = 24;
  var duration_title = emprise_params.duration_in_hours;
  var duration_unit = "by_hour";
  var duration_in_days = '<option value="">' + emprise_params.select + "</option>";
  if (event.target.classList.contains("by_days")) {
    var duration_counts = 31;
    var duration_title = emprise_params.duration_in_days;
    var duration_unit = "by_days";
  }
  for (let i = 1; i <= duration_counts; i++) {
    duration_in_days += '<option value="' + i + '">' + i + "</option>";
  }
  document.getElementById("emprise_duration_in_days_label").innerHTML = duration_title;
  document.getElementById("emprise_duration_in_days").innerHTML = duration_in_days;
  document.getElementById("duration_unit").value = duration_unit;
}
// Check a Cookie
function empriseCheckCookie() {
  var guest_auth_token = empriseGetCookie("guest_auth_token");
  if (typeof guest_auth_token === "undefined" || guest_auth_token === null) {
    var cValue = empriseRandomString(20);
    empriseSetCookie("guest_auth_token", cValue, 365);
  }
}

// Set a Cookie
function empriseSetCookie(cName, cValue, expDays) {
  let date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

// Get a Cookie
function empriseGetCookie(cName) {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split("; ");
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}

// Delete a Cookie
function empriseDeleteCookie(cName) {
  document.cookie = cName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

/* Random String */
function empriseRandomString(length) {
  var randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var result = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return result;
}

/* Wishlist section */
window.empriseSaveWishlist = function (self, postId, type = "otherpage") {
  let helptext = self.nextElementSibling;
  self.closest(".rlr-js-action-wishlist").disabled = true;

  var data = "action=emprise_save_wishlist&post_id=" + postId + "&type=" + type + "&nonce=" + emprise_params.nonce;

  ajaxCall(data, function (returnVal) {
    if (returnVal !== null) {
      var response = JSON.parse(returnVal);
      if (response.status == "saved") {
        self.closest(".rlr-js-action-wishlist").classList.add("is-active");
      } else {
        self.closest(".rlr-js-action-wishlist").classList.remove("is-active");
      }
      let pos = helptext.getBoundingClientRect().width / 2 + 24;
      helptext.style.left = `calc(100% - ${pos}px)`;
      if (helptext.closest(".rlr-js-detail-wrapper")) {
        let parentRight = helptext.closest(".rlr-js-detail-wrapper").getBoundingClientRect().right;
        let childRight = helptext.getBoundingClientRect().right;

        if (parentRight < childRight) {
          helptext.style.right = 0;
          helptext.style.left = "unset";
        } else {
          helptext.style.right = `calc(100% - ${pos}px)`;
        }
      } else {
        helptext.style.left = `calc(100% - ${pos}px)`;
      }

      self.closest(".rlr-js-action-wishlist").disabled = false;
    }
  });
};

/* Review Form */
var defaultConfig = {
  classTo: "js-fieldrow__item",
  errorClass: "has-danger",
  successClass: "has-success",
  errorTextParent: "js-fieldrow__item",
  errorTextTag: "div",
  errorTextClass: "text-help"
};

/* Get all the siblings of the element */
let empGetSiblings = function (e, tagType) {
  let siblings = [];
  // if no parent, return no sibling
  if (!e.parentNode) {
    return siblings;
  }
  let sibling = e.parentElement.children[0];
  while (sibling) {
    if (sibling === e) {
      break;
    }
    if (sibling.nodeType === 1 && sibling !== e) {
      siblings.push(sibling);
    }
    sibling = sibling.nextElementSibling;
  }
  siblings.push(e);
  return siblings;
};

/* Recommend button function to activate specific selection */
/* Reset recommend btn */
window.recommendBtnReset = function (event) {
  var btnRecommendation = document.getElementsByClassName("rlr-recommendation-button__button");

  Array.from(btnRecommendation).forEach((b) => {
    document.getElementById("comment_recommend").value = "";
    b.classList.remove("active");
  });
};

/********************
 * RecommendCallback Functions
 */
window.recommendCallback = function (event) {
  var btnRecommendation = document.getElementsByClassName("rlr-recommendation-button__button");
  Array.from(btnRecommendation).forEach((b) => {
    var recommendVal = "no";

    // Click on Recommend button
    b.addEventListener("click", function () {
      // Reset recommend
      recommendBtnReset();

      if (this.classList.contains("no_recommend")) {
        recommendVal = "no";
      } else {
        recommendVal = "yes";
      }
      this.classList.add("active");
      document.getElementById("comment_recommend").value = recommendVal;
      this.focus();
    });
  });
};

/* Review Star button function to activate specific ratig stars selection */
let reviewStarCallback = function (ratingBtn) {
  Array.from(ratingBtn).forEach(function (reviewEle, n) {
    reviewEle.addEventListener("click", function (e, c) {
      var tagType = e.target.tagName;
      if (tagType === "I") {
        var ratingError = document.getElementById("rating_error");
        if (ratingError !== null) {
          ratingError.parentElement.removeChild(ratingError);
        }
        starRatingReset();

        var getSibblingsOf = e.target;
        let siblings = empGetSiblings(getSibblingsOf, tagType);
        for (let index = 0; index < siblings.length; index++) {
          const element = siblings[index];
          if (element.classList.contains("flaticon-star")) {
            element.classList.remove("flaticon-star");
            element.classList.add("flaticon-star-1");
          }
        }
        document.getElementById("comment_rating").value = siblings.length;
      }
    });
  });
};

/* Reset star rating btn */
let starRatingReset = function () {
  if (document.querySelectorAll(".rlr-rating-stars__iconset i") !== null) {
    Array.from(document.querySelectorAll(".rlr-rating-stars__iconset i")).forEach(function (iconEle, n) {
      if (iconEle.classList.contains("flaticon-star-1")) {
        iconEle.classList.remove("flaticon-star-1");
        iconEle.classList.add("flaticon-star");
      }
    });
  }
};

var form = document.getElementById("reviewForm");
if (form !== null) {
  var pristine = new Pristine(form, defaultConfig);
  var reviewBtn = document.getElementsByClassName("rlr-review-form__submit");
  var recommendVal = "";

  var recommendBtn = document.getElementsByClassName("emprise_recommend_btn");
  recommendCallback(recommendBtn);

  var ratingBtn = document.getElementsByClassName("rlr-rating-stars__iconset");
  reviewStarCallback(ratingBtn);
  Array.from(reviewBtn).forEach(function (element) {
    element.addEventListener("click", function (e) {
      e.preventDefault();
      var ratingError = document.getElementById("rating_error");
      /* remove rating error div */
      if (ratingError !== null) {
        ratingError.parentElement.removeChild(ratingError);
        document.getElementById("comment_rating").parentElement.classList.remove("has-danger");
      }
      var valid = pristine.validate();
      var commentValid = false;
      var commentRating = 0;
      if (document.getElementById("comment_rating") !== null) {
        commentRating = document.getElementById("comment_rating").value;
      }
      if (commentRating >= 1 && commentRating <= 5) {
        commentValid = true;
      } else {
        commentValid = false;
        var ratingErrorDiv = document.createElement("div");
        ratingErrorDiv.setAttribute("class", "rlr-error text-help");
        ratingErrorDiv.setAttribute("id", "rating_error");
        ratingErrorDiv.innerHTML += emprise_params.rating_star_msg;
        if (document.getElementById("comment_rating") !== null) {
          document.getElementById("comment_rating").parentElement.classList.add("has-danger");
          document.getElementById("comment_rating").parentElement.appendChild(ratingErrorDiv);
        }
      }
      if (valid && commentValid) {
        let rlr_write_review = document.getElementById("rlr_write_review");
        rlr_write_review.disabled = true;
        rlr_write_review.value = emprise_params.loading_text;

        if (document.getElementById("comment_title")) {
          var commenterName = "";
          var commenterEmail = "";
          if (document.getElementById("commenter_name")) {
            commenterName = document.getElementById("commenter_name").value;
          }
          if (document.getElementById("commenter_email")) {
            commenterEmail = document.getElementById("commenter_email").value;
          }
          var commentTitle = document.getElementById("comment_title").value;
          var commentDesc = document.getElementById("comment_desc").value;
          var productId = document.getElementById("product_id").value;
          var commentRecommend = document.getElementById("comment_recommend").value;
          var commentRating = document.getElementById("comment_rating").value;
          /* Product Images */
          var images = document.getElementById("image-preview");
          var imagesList = retrieveImage(images, "reviewForm", "emprise_comment_save", emprise_params.nonce);
          imagesList.append("comment_recommend", commentRecommend);
          imagesList.append("comment_rating", commentRating);
          imagesList.append("product_id", productId);

          var data =
            "action=emprise_comment_save&product_id=" +
            productId +
            "&commenter_name=" +
            commenterName +
            "&commenter_email=" +
            commenterEmail +
            "&comment_title=" +
            commentTitle +
            "&comment_desc=" +
            commentDesc +
            "&comment_images=" +
            JSON.stringify(imagesList) +
            "&comment_recommend=" +
            commentRecommend +
            "&comment_rating=" +
            commentRating +
            "&nonce=" +
            emprise_params.nonce;
          ajaxCallForImage(imagesList, function (returnVal) {
            if (returnVal !== null) {
              var response = JSON.parse(returnVal);
              if (response.comment_id) {
                document.getElementById("reviewForm").reset();
                starRatingReset();
                recommendBtnReset();
                var imageDiv = document.getElementById("image-preview");
                imageDiv.innerHTML = "";
                var successDiv = document.getElementById("comment_respose");
                successDiv.classList.remove("alert-primary");
                successDiv.classList.add("alert");
                successDiv.classList.add("alert-success");
                var newNode = document.createElement("p");
                newNode.innerHTML = " " + emprise_params.comment_form_success;
                successDiv.innerHTML = "";
                successDiv.appendChild(newNode);
              } else {
                var failDiv = document.getElementById("comment_respose");
                failDiv.classList.remove("alert-success");
                failDiv.classList.add("alert");
                failDiv.classList.add("alert-primary");
                var newNode = document.createElement("p");
                newNode.innerHTML = " " + emprise_params.comment_form_fail;
                failDiv.innerHTML = "";
                failDiv.appendChild(newNode);
              }
            }

            rlr_write_review.disabled = false;
            rlr_write_review.value = emprise_params.btn_submit;
          });
        }
      }
    });
  });
}

/* Counter Increase, Decrease */

window.counterUpdate = function (event) {
  if (event.target.value !== "") {
    event.target.nextElementSibling.children[1].innerText = event.target.value;
  } else {
    event.target.nextElementSibling.children[1].innerText = 0;
  }
};

window.minusCount = function (event) {};
window.plusCount = function (event) {};

// Update Booking Counter
window.updateBookingCounter = function (event, counterType) {
  var counterField = "";
  counterField = event.parentElement.children[1];
  let prevBtn = event.parentElement.children[0];
  let nextBtn = event.parentElement.children[2];
  prevBtn.disabled = false;
  nextBtn.disabled = false;

  if (counterField) {
    var counter = counterField.value !== "" ? parseInt(counterField.value) : 0;
    if (counterType === "subtract") {
      if (parseInt(counter) > 0) {
        counter--;
      }
    } else {
      if (parseInt(counter) >= 0) {
        counter++;
      }
    }
    if (counter == 0) {
      prevBtn.disabled = true;
    }
    if (counter == 999) {
      nextBtn.disabled = true;
    }
    counterField.value = counter;
  }
};

/* Check Booking Availability */
var rlrjsBookingBtn = document.getElementById("rlr_js_booking_btn");
if (rlrjsBookingBtn !== null) {
  rlrjsBookingBtn.addEventListener("click", empriseAvailabilityBookingCallback, false);
}
function empriseAvailabilityBookingCallback() {
  var proceedToBookingBtn = rlrjsBookingBtn.classList.contains("proceed-to-booking");
  if (proceedToBookingBtn) {
    empriseBooking();
  } else {
    empriseCheckAvailability();
  }
}
function empriseCheckAvailability() {
  var agePriceArr = {};
  var selectedAddons = [];
  var agePrice = "";
  var bookingStartDate = "";
  var productId = "";

  if (document.getElementById("rlr_js_booking_btn")) {
    document.getElementById("rlr_js_booking_btn").classList.remove("proceed-to-booking");
  }

  var bookingAges = document.getElementsByClassName("rlr-booking-card__counter");
  Array.from(bookingAges).forEach(function (element) {
    if (element.getAttribute("data-name") !== "" && element.value !== "") {
      agePriceArr[element.getAttribute("data-name")] = element.value;
      agePrice += "&" + element.getAttribute("data-name") + "=" + element.value;
    }
  });

  if (document.getElementById("rlr-booking-dateranges-input") !== null) {
    bookingStartDate = document.getElementById("rlr-booking-dateranges-input").value;
  }

  if (document.getElementById("product_id") !== null) {
    productId = document.getElementById("product_id").value;
  }

  var data = "action=emprise_check_availability&booking_startDate=" + bookingStartDate + "&product_id=" + productId + agePrice + "&nonce=" + emprise_params.nonce;

  var bookingFormId = document.getElementById("rlr_js_booking_results");
  if (bookingFormId !== null) {
    if (bookingFormId.classList.contains("rlr-booking-card__results--found")) {
    } else {
      bookingFormId.classList.add("rlr-booking-card__results--checking");
      bookingFormId.classList.add("ph-item");
      bookingFormId.innerHTML =
        '<div class="ph-col-12 rlr-booking-card__ph-12"><div class="ph-row rlr-booking-card__placeholder-row"><div class="col-4 big empty"></div><div class="col-4 big rlr-booking-card__placeholder-row--item"></div><div class="col-4 big empty"></div><div class="col-12 rlr-booking-card__placeholder-row--item"></div><div class="col-5 empty"></div><div class="col-2 rlr-booking-card__placeholder-row--item"></div><div class="col-5 empty"></div></div></div>';
    }
    ajaxCall(data, function (returnVal) {
      if (returnVal !== null) {
        var response = JSON.parse(returnVal);
        if (typeof response.status !== "undefined" && response.status === "error") {
          bookingFormId.classList.remove("rlr-booking-card__results--checking");
          bookingFormId.classList.remove("ph-item");
          bookingFormId.innerHTML = response.content;
        } else if (typeof response.status !== "undefined" && response.status === "success") {
          bookingFormId.classList.remove("rlr-booking-card__results--checking");
          bookingFormId.classList.add("rlr-booking-card__results--found");
          bookingFormId.classList.remove("ph-item");
          bookingFormId.innerHTML = response.content;
          if (typeof response.total_price_content !== "undefined") {
            var thisElement = document.querySelector(".rlr-booking-card__price-details");
            thisElement.innerHTML = response.total_price_content;
          }
          document.getElementById("rlr_js_booking_btn").innerHTML = emprise_params.proceed_to_booking;
          document.getElementById("rlr_js_booking_btn").classList.add("proceed-to-booking");
        }
        if (document.getElementById("rlr_js_booking_form").classList.contains("ph-item")) {
          document.getElementById("rlr_js_booking_form").classList.remove("ph-item");
          document.getElementById("booking_form_wrapper").style.display = "";
          document.getElementById("booking_form_loader").style.display = "none";
        }
      }
    });
  }
}

window.bookingPopoverOnClose = function () {
  onBookingItemReSelect();
};

function onBookingItemReSelect() {
  var isResultFound = document.querySelector(".rlr-booking-card__results--found");
  if (isResultFound) {
    document.getElementById("rlr_js_booking_form").classList.add("ph-item");
    document.getElementById("booking_form_wrapper").style.display = "none";
    document.getElementById("booking_form_loader").style.display = "";
    empriseCheckAvailability();
  }
}

let bookingDates = document.getElementsByClassName("rlr-js-booking-form-daterange");
if (bookingDates.length) {
  Array.from(bookingDates).forEach((d) => {
    d.getElementsByTagName("input")[0].addEventListener("changeDate", function (evt) {
      onBookingItemReSelect();
    });
  });
}

/* Proceed to booking */
function empriseBooking() {
  var productId = "";
  if (document.getElementById("product_id") !== null) {
    productId = document.getElementById("product_id").value;
  }
  var bookingFormId = document.getElementById("rlr_js_booking_results");
  if (bookingFormId !== null) {
    document.getElementById("rlr_js_booking_form").classList.add("ph-item");
    document.getElementById("booking_form_wrapper").style.display = "none";
    document.getElementById("booking_form_loader").style.display = "";
    var data = "action=emprise_book_now&product_id=" + productId + "&nonce=" + emprise_params.nonce;
    ajaxCall(data, function (returnVal) {
      if (returnVal !== null) {
        var response = JSON.parse(returnVal);
        if (typeof response.status !== "undefined" && response.status === "error") {
          bookingFormId.innerHTML = response.content;
          document.getElementById("rlr_js_booking_form").classList.remove("ph-item");
          document.getElementById("booking_form_wrapper").style.display = "";
          document.getElementById("booking_form_loader").style.display = "none";
        } else if (typeof response.status !== "undefined" && response.status === "success") {
          if (emprise_params.woo_checkout_page !== "") {
            window.location.href = emprise_params.woo_checkout_page;
          }
        }
      }
    });
  }
}

/* Dynamic Filter  */
window.empriseDynamicFilter = function (event) {
  var sorting_option = "active";
  if (event.classList.contains("active")) {
    event.classList.remove("active");
    sorting_option = "deactive";
  } else {
    event.classList.add("active");
    sorting_option = "active";
  }
  empriseSortDynamicFilter(event, sorting_option);
  filterProducts();
};

/* Sorting Dynamic Filter   */
window.empriseSortDynamicFilter = function (event, sorting_option) {
  var list, i, switching, b, shouldSwitch;
  list = document.getElementById("rlr_js_dynamic_filter_options");
  switching = true;
  while (switching) {
    // start by saying: no switching is done:
    switching = false;
    b = list.getElementsByTagName("li");
    // Loop through all list-items:
    for (i = 0; i < b.length - 1; i++) {
      shouldSwitch = false;

      if (Number(b[i].firstElementChild.classList.length) < Number(b[i + 1].firstElementChild.classList.length)) {
        shouldSwitch = true;

        break;
      }
    }
    if (shouldSwitch) {
      b[i].parentNode.insertBefore(b[i + 1], b[i]);
      switching = true;
    }
  }
};

/* Filter Products => Sorting */
window.empriseSortProducts = function (event, sort_by) {
  document.getElementById("product_sort_order").value = sort_by;
  filterProducts();
};

/* Filter Products */
window.empriseFilterProducts = function (event, vals) {
  filterProducts();
};
/* Filter By destination */
window.empriseDestiFilterProducts = function (event, vals) {
  var selectedDesti = "";
  filterProducts("filter", vals);
  empriseSetCookie("location", vals, 365);
  let filterDestiAutoCompleteItem = document.querySelectorAll(".rlr-filter-desti-autocomplete");
  rlrHideAllSuggestions("", filterDestiAutoCompleteItem);
  if (event.querySelector(".rlr-autocomplete__sub-text") !== null) {
    selectedDesti = event.querySelector(".rlr-autocomplete__sub-text").textContent;
  } else {
    selectedDesti = event.querySelector(".rlr-autocomplete__text").textContent;
  }
  if (document.getElementById("setFilterDestination") !== null) {
    document.getElementById("setFilterDestination").innerText = selectedDesti;
  }
};

/* Filter By Date Range */
let filterDateRange = document.getElementsByClassName("emprise_filter-endDate");
if (filterDateRange.length) {
  Array.from(filterDateRange).forEach((filterDates) => {
    filterDates.getElementsByTagName("input")[0].addEventListener("hide", function (evt) {
      dateRangeFilter();
      filterProducts();
    });
  });
}

function dateRangeFilter() {
  var startDateField = document.querySelector('input[date-name="emprise_filter_startdate"]');
  const startDate = startDateField.value;
  var endDateField = document.querySelector('input[date-name="emprise_filter_enddate"]');
  const endDate = endDateField.value;
}

/* Pagination */
window.empriseFilterPagnation = function (event, vals) {
  if (vals !== "") {
    document.getElementById("paged").value = vals;
    var filterPaginations = document.getElementsByClassName("rlr-pagination__page-link--counter");
    Array.from(filterPaginations).forEach(function (element) {
      element.parentElement.classList.remove("active");
    });
    if (!event.classList.contains("rlr-pagination__page-link--next") && !event.classList.contains("rlr-pagination__page-link--prev")) {
      event.parentElement.classList.add("active");
    }
    filterProducts("pagination");
  }
};

function filterProducts(opt_mode = "filter", searchVal = "") {
  var search_param = "";
  var productTags = [];
  var tourDurations = [];
  var productRatings = [];
  var ageGroups = [];
  var physicalLevels = [];
  var dynamicThemes = [];
  var dynamicTags = [];
  var dateRange = "";
  var productCats = "";
  var location = "";

  if (document.getElementById("listing_holder") !== null) {
    document.getElementById("filter_loader").classList.remove("rlr-js-input-groups__hide");
    document.getElementById("listing_holder").style.display = "none";
    if (opt_mode === "filter") {
      document.getElementById("rlr_js_pagination").style.display = "none";
    }

    const posts_per_page = document.getElementById("posts_per_page").value;
    if (opt_mode == "filter") {
      document.getElementById("paged").value = 1;
    }
    const paged = document.getElementById("paged").value;
    const sort_order = document.getElementById("product_sort_order").value;

    /* Price Range Slider */
    let min_price = emprise_params.min_price;
    let max_price = emprise_params.max_price;
    var parent = document.querySelector(".rlr-range-slider");
    if (parent) {
      var minPriceHolder = "";
      var maxPriceHolder = "";
      var numberS = parent.querySelectorAll("input[type=number]");
      var numberInputFirst = parseFloat(numberS[0].value);
      var numberInputSecond = parseFloat(numberS[1].value);
      if (numberInputFirst > numberInputSecond) {
        maxPriceHolder = numberInputFirst;
        minPriceHolder = numberInputSecond;
      } else {
        maxPriceHolder = numberInputSecond;
        minPriceHolder = numberInputFirst;
      }

      // This is necessary
      if (parseFloat(min_price) === parseFloat(minPriceHolder) && parseFloat(max_price) === parseFloat(maxPriceHolder)) {
        min_price = "";
        max_price = "";
      } else {
        min_price = minPriceHolder;
        max_price = maxPriceHolder;
      }
    } else {
      min_price = "";
      max_price = "";
    }

    /* Dynamic Filter */
    var dynamicFilterOptions = document.querySelectorAll(".rlr-icon-text__card");
    Array.from(dynamicFilterOptions).forEach(function (element) {
      if (element.classList.contains("active")) {
        var dynamic_tax = element.getAttribute("data-taxonomy");
        var dynamic_slug = element.getAttribute("data-slug");
        if (dynamic_tax === "product-theme") {
          dynamicThemes.push(dynamic_slug);
        } else {
          dynamicTags.push(dynamic_slug);
        }
      }
    });

    /* Product Tags */
    var productTagOptions = document.querySelectorAll('input[name="rlr_product_tags"]:checked');
    Array.from(productTagOptions).forEach(function (element) {
      if (element) {
        productTags.push(element.value);
      }
    });

    /* Durations */
    var durationOptions = document.querySelectorAll('input[name="rlr_product_durations"]:checked');
    Array.from(durationOptions).forEach(function (element) {
      if (element) {
        tourDurations.push(element.value);
      }
    });

    /* Product Ratings */
    var productRatingsOptions = document.querySelectorAll('input[name="rlr_product_ratings"]:checked');
    Array.from(productRatingsOptions).forEach(function (element) {
      if (element) {
        productRatings.push(element.value);
      }
    });

    /* Age Group */
    var ageGroupOptions = document.querySelectorAll('input[name="rlr_age_groups"]:checked');
    Array.from(ageGroupOptions).forEach(function (element) {
      if (element) {
        ageGroups.push(element.value);
      }
    });

    /* Physical Level */
    var physicalLevelOptions = document.querySelectorAll('input[name="rlr_physical_level"]:checked');
    Array.from(physicalLevelOptions).forEach(function (element) {
      if (element) {
        physicalLevels.push(element.value);
      }
    });

    /* Product Category */
    productCats = document.getElementById("product_cats").value;

    /* Filter by Date */
    var selectedSearchDateRange = document.getElementById("rlr-search-dateranges-input");
    if (selectedSearchDateRange !== null) {
      var dateRange = selectedSearchDateRange.value.replace(" to ", "___");
    }

    /* Filter by Destination */
    if (searchVal !== "") {
      location = searchVal;
    }

    /* Search parameter URL */
    if (location !== "") {
      search_param = search_param + "&location=" + location;
    }
    if (tourDurations.length !== 0) {
      search_param = search_param + "&duration=" + tourDurations.join("___");
    }
    if (productTags.length !== 0) {
      search_param = search_param + "&tags=" + productTags.join("___");
    }
    if (ageGroups.length !== 0) {
      search_param = search_param + "&agegrp=" + ageGroups.join("___");
    }
    if (physicalLevels.length !== 0) {
      search_param = search_param + "&physicallevel=" + physicalLevels.join("___");
    }
    if (productRatings.length !== 0) {
      search_param = search_param + "&ratings=" + productRatings.join("___");
    }
    if (dynamicThemes.length !== 0) {
      search_param = search_param + "&dthemes=" + dynamicThemes.join("___");
    }
    if (dynamicTags.length !== 0) {
      search_param = search_param + "&dtags=" + dynamicTags.join("___");
    }
    if (dateRange !== "") {
      search_param = search_param + "&daterange=" + dateRange;
    }
    if (productCats !== "") {
      search_param = search_param + "&cats=" + productCats;
    }
    if (paged != "") {
      search_param = search_param + "&page=" + paged;
    }
    if (min_price != "") {
      search_param = search_param + "&min_price=" + min_price;
    }
    if (max_price != "") {
      search_param = search_param + "&max_price=" + max_price;
    }

    /* Destination Template */
    var ajax_reload_url = emprise_params.search_url;
    var updateDestination = document.getElementById("update_destination");
    var updateCategory = document.getElementById("update_category");
    var currentUrl = document.getElementById("current_url");
    if (updateDestination !== null) {
      empriseSetCookie("location", updateDestination.value, 365);
    }
    if (currentUrl !== null) {
      ajax_reload_url = currentUrl.value;
    }

    if (updateCategory !== null) {
      productCats = updateCategory.value;
      empriseDeleteCookie("location");
    }

    window.history.pushState("Emprise", emprise_params.site_name, ajax_reload_url + "?" + search_param);

    var data =
      "action=emprise_filter_products&paged=" +
      paged +
      "&min_price=" +
      min_price +
      "&max_price=" +
      max_price +
      "&posts_per_page=" +
      posts_per_page +
      "&sort_order=" +
      sort_order +
      "&cats=" +
      productCats +
      "&tags=" +
      JSON.stringify(productTags) +
      "&duration=" +
      JSON.stringify(tourDurations) +
      "&ratings=" +
      JSON.stringify(productRatings) +
      "&agegrp=" +
      JSON.stringify(ageGroups) +
      "&physicallevel=" +
      JSON.stringify(physicalLevels) +
      "&dthemes=" +
      JSON.stringify(dynamicThemes) +
      "&dtags=" +
      JSON.stringify(dynamicTags) +
      "&daterange=" +
      JSON.stringify(dateRange) +
      "&location=" +
      location +
      "&nonce=" +
      emprise_params.nonce;

    ajaxCall(data, function (returnVal) {
      if (returnVal !== null) {
        var response = JSON.parse(returnVal);
        if (response.status === "success") {
          document.getElementById("listing_holder").innerHTML = response.content;
          document.getElementById("rlr_js_record_showing").innerHTML = response.total_record;
          document.getElementById("rlr_js_pagination").innerHTML = response.pagination;
          document.getElementById("listing_holder").style.display = "";
          if (opt_mode === "filter") {
            document.getElementById("rlr_js_pagination").style.display = "";
          }
          document.getElementById("filter_loader").classList.add("rlr-js-input-groups__hide");

          const productMultiImageSwiper = new Swiper(".rlr-js-product-multi-image-swiper", {
            modules: [Navigation, Pagination, FreeMode],
            spaceBetween: 30,
            effect: "fade",
            preventClicks: false,
            navigation: {
              nextEl: ".splide__arrow--next",
              prevEl: ".splide__arrow--prev"
            },
            pagination: {
              el: ".swiper-pagination",
              clickable: true
            }
          });
        }
      }
    });
  }
}

/* Emprise => Add product form => Multiple dates options */
var product_rlr_tour_occurance_options = document.getElementsByName("rlr_tour_occurance");
Array.from(product_rlr_tour_occurance_options).forEach(function (element) {
  element.addEventListener("click", empriseTourOccurance, false);
});

function empriseTourOccurance(event) {
  var thisDiv = "date_option_" + event.target.value;
  var dateOptions = document.getElementsByClassName("rlr-date-selection");
  Array.from(dateOptions).forEach((d) => {
    if (d.classList.contains("rlr-product-form--hide") !== null || d.classList.contains("rlr-product-form--hide") === false) {
      d.classList.add("rlr-product-form--hide");
    }
  });

  document.getElementById(thisDiv).classList.remove("rlr-product-form--hide");
}

/* Flatpickr => Date Picker & Calendar */
flatpickr("#rlr_js_product_form_date_multiple_weekly", {
  mode: "multiple",
  minDate: "today",
  // altInput: true,
  // altFormat: "F j, Y",
  dateFormat: "Y-m-d",
  allowInput: true
});

/* Discount for Date Range */
const discount_date_range = document.querySelector(".discount_date_range");
const fp = flatpickr(".discount_date_range", {
  mode: "range",
  minDate: "today",
  altInput: true,
  altFormat: "F j, Y",
  dateFormat: "Y-m-d",
  allowInput: true,
  showMonths: 2
});

/* Search Form */
const searchDatePicker = flatpickr("#rlr-search-dateranges-input", {
  mode: "range",
  minDate: "today",
  altInput: true,
  altFormat: "F j, Y",
  dateFormat: "Y-m-d",
  showMonths: 2,
  onClose: function (selectedDates, dateStr, instance) {
    filterProducts();
  }
});

// on mobile devices showMonths: 1
if (window.innerWidth < 768) {
  const searchDatePicker = flatpickr("#rlr-search-dateranges-input", {
    mode: "range",
    minDate: "today",
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    allowInput: true,
    showMonths: 1,
    monthSelectorType: "static",
    onClose: function (selectedDates, dateStr, instance) {
      filterProducts();
    }
  });
}

// Price range slider
(function () {
  var parent = document.querySelector(".rlr-range-slider");
  if (!parent) return;
  let typingTimer; //timer identifier
  let doneTypingInterval = 1000; //time in ms (5 seconds)

  var rlrRangeSlide = parent.querySelectorAll("input[type=range]"),
    numberS = parent.querySelectorAll("input[type=number]");

  rlrRangeSlide.forEach(function (el) {
    el.oninput = function () {
      var slide1 = parseFloat(rlrRangeSlide[0].value),
        slide2 = parseFloat(rlrRangeSlide[1].value);

      if (slide1 > slide2) {
        [slide1, slide2] = [slide2, slide1];
      }
      numberS[0].value = slide1;
      numberS[1].value = slide2;
    };
    el.addEventListener("change", (ele) => {
      filterProducts();
    });
  });

  numberS.forEach(function (el) {
    el.oninput = function () {
      var number1 = parseFloat(numberS[0].value),
        number2 = parseFloat(numberS[1].value);

      if (number1 > number2) {
        var tmp = number1;
        numberS[0].value = number2;
        numberS[1].value = tmp;
      }

      rlrRangeSlide[0].value = number1;
      rlrRangeSlide[1].value = number2;
    };
    el.addEventListener("keyup", (event) => {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(filterProducts, doneTypingInterval);
    });
    // On keydown reset the timeout value
    el.addEventListener("keydown", (event) => {
      clearTimeout(typingTimer);
    });
  });
})();
function rangeInputValueSet() {
  var parent = document.querySelector(".rlr-range-slider");
  if (!parent) return;
  var rlrRangeSlide = parent.querySelectorAll("input[type=range]"),
    numberS = parent.querySelectorAll("input[type=number]");
  var numberInputFirst = numberS[0];
  var numberInputSecond = numberS[1];
  let maxPrice = 0;
  let minPrice = 0;
  if (numberInputFirst > numberInputSecond) {
    maxPrice = numberInputFirst;
    minPrice = numberInputSecond;
  } else {
    maxPrice = numberInputSecond;
    minPrice = numberInputFirst;
  }
}

/* Header Search */
(function () {
  const el = document.querySelector(".rlr-header-search__button");
  if (el !== null) {
    el.onclick = function () {
      el.parentNode.classList.toggle("open");
    };
  }
})();

// Header Destination Search
let rlrAutoCompleteItem = document.querySelectorAll(".rlr-js-autocomplete__item");

// Filter page destination search
let filterDestiAutoCompleteItem = document.querySelectorAll(".rlr-filter-desti-autocomplete");

function rlrToggleSearchBtn() {
  const el = document.querySelector(".rlr-header-search__button");
  if (el !== null) {
    if (window.innerWidth >= 992) {
      if (el.parentNode.classList.contains("open")) {
        el.parentNode.classList.toggle("open");
      }
    } else {
      el.parentNode.classList.add("open");
    }
  }
}
function rlrItemSearch(inputValue = "") {
  if (inputValue !== "") {
    if (filterDestiAutoCompleteItem !== null) {
      rlrHideAllSuggestions(inputValue, filterDestiAutoCompleteItem);
    }
  } else if (document.getElementById("rlrSearchBox") !== null) {
    let search_query = document.getElementById("rlrSearchBox").value;
    //Use innerText if all contents are visible
    //Use textContent for including hidden elements
    if (rlrAutoCompleteItem !== null) {
      rlrHideAllSuggestions(search_query, rlrAutoCompleteItem);
    }
  }
}

function rlrHideAllSuggestions(search_query, autocompleteEle) {
  for (var i = 0; i < autocompleteEle.length; i++) {
    if (search_query === "") {
      autocompleteEle[i].classList.add("is-hidden");
    } else {
      if (autocompleteEle[i].textContent.toLowerCase().includes(search_query.toLowerCase())) {
        autocompleteEle[i].classList.remove("is-hidden");
      } else {
        autocompleteEle[i].classList.add("is-hidden");
      }
    }
  }
}

// A little delay
let typingTimer;
let typeInterval = 50;
let searchInput = document.getElementById("rlrSearchBox");
if (searchInput !== null) {
  searchInput.addEventListener("keyup", () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(rlrItemSearch, typeInterval);
  });
}

function toggleDestiAndInut() {
  var filterDestinationInput = document.querySelector("#filterDestination");
  var destinationTextDiv = document.querySelector("#setFilterDestination");
  if (filterDestinationInput !== null) {
    filterDestinationInput.remove();
  }
  if (destinationTextDiv !== null) {
    if (destinationTextDiv.classList.contains("is-hidden")) {
      destinationTextDiv.classList.toggle("is-hidden");
      var resetDestBtn = document.getElementsByClassName("resetDest");
      if (resetDestBtn.length > 0) {
        if (resetDestBtn[0].classList.contains("is-hidden")) {
          resetDestBtn[0].classList.remove("is-hidden");
        }
      }
    }
  }
}

// Detect all clicks on the document
document.addEventListener("click", function (e) {
  // clicking inside destination option list element - do nothing!
  if (e.target.closest(".rlr-header-search__wrapper")) {
    toggleDestiAndInut();
    return;
  } else if (e.target.closest("#setFilterDestination")) {
    // Check if click inside destination text
  } else if (e.target.closest("#filterDestination")) {
    // Check if click inside destination set input textfield
  } else {
    // For every other click close the input textfield, show the prev destination & side hide destination list if open
    toggleDestiAndInut();
    rlrHideAllSuggestions("", filterDestiAutoCompleteItem);
    //return;
  }

  rlrToggleSearchBtn();
  window.addEventListener("resize", rlrToggleSearchBtn);
  // For header Search
  if (searchInput !== null && searchInput.value !== "") {
    rlrHideAllSuggestions("", rlrAutoCompleteItem);
    searchInput.value = "";
  }
});

/* Filter Products */
window.searchByDestination = function (event, searchLocation, displayLocation) {
  if (document.getElementById("rlrSearchBox") !== null) {
    document.getElementById("rlrSearchBox").value = displayLocation;
    rlrHideAllSuggestions("", rlrAutoCompleteItem);
  }
  var search_param = "?&location=" + searchLocation;
  var searchUrl = emprise_params.search_url;
  location.href = searchUrl + search_param;
};

/* Set Footer Widget width */
let subscribeForm = document.getElementsByClassName("emprise_subscribe_widget");
if (subscribeForm.length > 0) {
  Array.from(subscribeForm).forEach((d) => {
    if (d.classList.contains("rlr-footer__menu__col")) {
      d.classList.remove("rlr-footer__menu__col");
      d.classList.add("rlr-footer__menu__col--lg");
    }
  });
}

/* Dynamic URL for language */
var footerAddClasses = document.getElementsByClassName("rlr-footer__getintouch__col--address");
var currentLang = emprise_params.current_lang;
if (footerAddClasses.length > 0 && currentLang !== "") {
  Array.from(footerAddClasses).forEach(function (footerAddress) {
    var footerAddressLink = footerAddress.querySelectorAll("a");
    for (var i = 0; i < footerAddressLink.length; i++) {
      footerAddressLink[i].addEventListener("click", function (link) {
        link.preventDefault();
        var hrefLink = link.target.href;
        location.href = hrefLink + "?lang=" + currentLang;
      });
    }
  });
}

/* React Comment */
window.reactComment = function (event, commentID, reactType) {
  let thisEvent = event;
  var data = "action=emprise_react_comment&commentID=" + commentID + "&reactType=" + reactType + "&nonce=" + emprise_params.nonce;

  ajaxCall(data, function (returnVal) {
    if (returnVal !== null) {
      var response = JSON.parse(returnVal);
      if (response.status === "success") {
        thisEvent.innerHTML = response.count;
      }
    }
  });
};

/* Visitors location track
------------------------ */
function getLocation() {
  if (navigator.geolocation) {
    var emprise_visitor = empriseGetCookie("emprise_visitor");

    //if (emprise_visitor === null || emprise_visitor === 'undefined') {
    navigator.geolocation.watchPosition(showPosition);
    // }
  } else {
    console.log("This browser does not support Geolocation Service.");
  }
}

function showPosition(position) {
  var lat = position.coords.latitude;
  var long = position.coords.longitude;
  var data = "action=emprise_user_location&latitude=" + lat + "&longitude=" + long + "&nonce=" + emprise_params.nonce;

  ajaxCall(data, function (returnVal) {
    if (returnVal !== null) {
      var response = JSON.parse(returnVal);
      if (response.status !== null && response.status === "success") {
        empriseSetCookie("emprise_visitor", response.address, 365);
      }
    }
  });
}

/* Emprise Signup Form Submission
--------------------------------- */
var defaultConfig = {
  classTo: "rlr-authforms__inputgroup",
  errorClass: "has-danger",
  successClass: "has-success",
  errorTextParent: "rlr-authforms__inputgroup",
  errorTextTag: "div",
  errorTextClass: "text-help"
};
var signupForm = document.getElementById("signupForm");
if (signupForm !== null) {
  var signupBtn = document.getElementById("emprise_btn_signup");
  signupBtn.addEventListener("click", function (e) {
    e.preventDefault();

    var pristine = new Pristine(signupForm, defaultConfig);
    var valid = pristine.validate();
    if (valid) {
      let loadMessage = document.getElementById("load_message");
      let first_name = document.getElementById("first_name").value;
      let last_name = document.getElementById("last_name").value;
      let user_name = document.getElementById("user_name").value;
      let user_email = document.getElementById("user_email").value;
      let user_password = document.getElementById("user_password").value;

      loadMessage.classList.remove("alert-secondary");
      loadMessage.classList.remove("alert-danger");
      loadMessage.classList.remove("alert-success");
      loadMessage.innerHTML = emprise_params.loading_text;
      loadMessage.classList.add("alert");
      loadMessage.classList.add("alert-secondary");

      var data =
        "action=emprise_signup&first_name=" +
        first_name +
        "&last_name=" +
        last_name +
        "&user_name=" +
        user_name +
        "&user_email=" +
        user_email +
        "&user_password=" +
        user_password +
        "&nonce=" +
        emprise_params.nonce;

      ajaxCall(data, function (returnVal) {
        if (returnVal !== null) {
          var response = JSON.parse(returnVal);

          loadMessage.classList.remove("alert-secondary");
          if (response.status === "success") {
            document.getElementById("load_message").innerHTML = "";
            loadMessage.classList.remove("alert");
            document.getElementById("rlr_signup_header").innerHTML = response.content;
            document.getElementById("rlr_signup_body").remove();
          } else {
            document.getElementById("load_message").innerHTML = response.message;
            loadMessage.classList.add("alert-danger");
          }
        }
      });
    }
  });
}

// Sign In Form
var signinForm = document.getElementById("emprise-singin-form");
if (signinForm !== null) {
  var signinBtn = document.getElementById("emprise-signin-btn");
  signinBtn.addEventListener("click", function (e) {
    e.preventDefault();
    var pristine = new Pristine(signinForm, defaultConfig);
    var valid = pristine.validate();
    if (valid) {
      let loadMessage = document.getElementById("load_message");
      let login_email = document.getElementById("login_email").value;
      let login_password = document.getElementById("login_password").value;
      let login_remember = document.querySelector('input[name="login_remember"]:checked');
      var remember_me = false;
      if (login_remember && login_remember !== null) {
        remember_me = true;
      }

      loadMessage.classList.remove("alert-secondary");
      loadMessage.classList.remove("alert-danger");
      loadMessage.classList.remove("alert-success");
      loadMessage.innerHTML = emprise_params.loading_text;
      loadMessage.classList.add("alert");
      loadMessage.classList.add("alert-secondary");

      var data = "action=emprise_signin&login_email=" + login_email + "&login_password=" + login_password + "&remember_me=" + remember_me + "&nonce=" + emprise_params.nonce;

      ajaxCall(data, function (returnVal) {
        if (returnVal !== null) {
          var response = JSON.parse(returnVal);
          document.getElementById("load_message").innerHTML = response.message;
          loadMessage.classList.remove("alert-secondary");
          if (response.status === "success") {
            signinForm.reset();
            loadMessage.classList.add("alert-success");
            document.getElementById("login_email").value = "";
            document.getElementById("login_password").value = "";
            var myAccountLink = emprise_params.my_account;
            location.href = myAccountLink;
          } else {
            loadMessage.classList.add("alert-danger");
          }
        }
      });
    }
  });
}

/* Emprise =>  Forgot Password Form
--------------------------------- */
var defaultConfig = {
  classTo: "rlr-authforms__inputgroup",
  errorClass: "has-danger",
  successClass: "has-success",
  errorTextParent: "rlr-authforms__inputgroup",
  errorTextTag: "div",
  errorTextClass: "text-help"
};
var forgotPassForm = document.getElementById("forgotPassForm");
if (forgotPassForm !== null) {
  var forgotClickBtn = document.getElementById("emprise_btn_forgot_pwd");
  forgotClickBtn.addEventListener("click", function (e) {
    e.preventDefault();

    var pristine = new Pristine(forgotPassForm, defaultConfig);
    var valid = pristine.validate();
    if (valid) {
      let loadMessage = document.getElementById("load_message");
      let user_email = document.getElementById("user_email").value;

      loadMessage.classList.remove("alert-secondary");
      loadMessage.classList.remove("alert-danger");
      loadMessage.classList.remove("alert-success");
      loadMessage.innerHTML = emprise_params.loading_text;
      loadMessage.classList.add("alert");
      loadMessage.classList.add("alert-secondary");

      var data = "action=emprise_forgot_pass&&user_email=" + user_email + "&nonce=" + emprise_params.nonce;

      ajaxCall(data, function (returnVal) {
        if (returnVal !== null) {
          var response = JSON.parse(returnVal);

          loadMessage.classList.remove("alert-secondary");
          if (response.status === "success") {
            document.getElementById("load_message").innerHTML = "";
            loadMessage.classList.remove("alert");
            document.getElementById("rlr_forgot_header").innerHTML = response.content;
            document.getElementById("rlr_forgot_body").remove();
            forgotPassForm.reset();
          } else {
            document.getElementById("load_message").innerHTML = response.message;
            loadMessage.classList.add("alert-danger");
          }
        }
      });
    }
  });
}

/* Emprise =>  Change Password Form
--------------------------------- */
var defaultConfig = {
  classTo: "rlr-authforms__inputgroup",
  errorClass: "has-danger",
  successClass: "has-success",
  errorTextParent: "rlr-authforms__inputgroup",
  errorTextTag: "div",
  errorTextClass: "text-help"
};
var resetPassForm = document.getElementById("resetPassForm");
if (resetPassForm !== null) {
  var reestClickBtn = document.getElementById("emprise_btn_reset_pwd");
  reestClickBtn.addEventListener("click", function (e) {
    e.preventDefault();

    var pristine = new Pristine(resetPassForm, defaultConfig);
    var valid = pristine.validate();
    if (valid) {
      let loadMessage = document.getElementById("load_message");
      let new_password = document.getElementById("new_password").value;
      let confirm_password = document.getElementById("confirm_password").value;
      let user_id = document.getElementById("user_id").value;

      loadMessage.classList.remove("alert-secondary");
      loadMessage.classList.remove("alert-danger");
      loadMessage.classList.remove("alert-success");
      loadMessage.innerHTML = emprise_params.loading_text;
      loadMessage.classList.add("alert");
      loadMessage.classList.add("alert-secondary");

      var data = "action=emprise_reset_pass&&new_password=" + new_password + "&confirm_password=" + confirm_password + "&user_id=" + user_id + "&nonce=" + emprise_params.nonce;

      ajaxCall(data, function (returnVal) {
        if (returnVal !== null) {
          var response = JSON.parse(returnVal);
          document.getElementById("load_message").innerHTML = response.message;
          loadMessage.classList.remove("alert-secondary");
          if (response.status === "success") {
            resetPassForm.reset();
            loadMessage.classList.add("alert-success");
          } else {
            loadMessage.classList.add("alert-danger");
          }
        }
      });
    }
  });
}

/* Search result heading */
function editData(e) {
  const el = e.target;
  const prevEl = e.target;
  const input = document.createElement("input");
  input.setAttribute("id", "filterDestination");
  el.replaceWith(input);
  const resultCountDiv = document.getElementById("emprise-result-count");
  if (resultCountDiv !== null) {
    empriseDestiText = prevEl.text;
    prevEl.classList.add("is-hidden");
    resultCountDiv.insertBefore(prevEl, input);
  }

  /* Destination Search in Filter product Page */
  input.addEventListener("keyup", () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(function () {
      rlrItemSearch(input.value);
    }, typeInterval);
  });
  input.focus();
}

for (const child of document.querySelectorAll("[data-editable]")) {
  child.onclick = editData;
}

var resetDestinationBtns = document.getElementsByClassName("resetDest");
if (resetDestinationBtns !== null && resetDestinationBtns.length > 0) {
  Array.from(resetDestinationBtns).forEach(function (resetDestinationBtn) {
    resetDestinationBtn.addEventListener("click", () => {
      resetDestination();
    });
  });
}

function resetDestination() {
  const searchDesti = document.createElement("strong");
  searchDesti.setAttribute("id", "setFilterDestination");
  searchDesti.onclick = editData;
  searchDesti.textContent = emprise_params.search_loation;

  var parentEle = document.getElementById("emprise-result-count");

  if (parentEle !== null) {
    var newDivToShow = [];
    for (let i = 0; i < parentEle.children.length; i++) {
      var newContent = "";
      newContent = parentEle.children[i];
      if (i === 0) {
        newContent.textContent = "";
      } else if (i === 1) {
        newContent.textContent = emprise_params.search_loation;
      } else if (i === 2) {
        newContent.classList.add("is-hidden");
      }
      newDivToShow.push(newContent);
    }
    parentEle.innerHTML = "";
    empriseDeleteCookie("location");
    filterProducts();
    newDivToShow.forEach((item) => parentEle.appendChild(item));
  }
}

// WCFM vendor redirect add product to dashboard
jQuery(document).ready(function ($) {
  $("#add_new_product_dashboard").on("click", function (event) {
    event.preventDefault();

    let clickedUrl = $(this).attr("href");
    let newUrl = clickedUrl.replace("/store-manager/products-manage/", "/add-your-listing/");

    window.location.href = newUrl;
  });
});

import "./banner";
